.footer{
  background: #51b8b4;
  padding: 5px 0;
  .logo,.box{
    max-width: 1318px;
    margin: 0 auto;
  }
  .box{
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    color: white;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: .6;
    .left{
      img{
        width: 50px;
      }
    }
    .right{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 50px;
      font-weight: bold;
      p{
        margin: 0;
      }
      &Item{
        color: white;
        cursor: pointer;
        padding-left: 30px;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    // .line{
    //   display: inline-block;
    //   vertical-align: top;
    //   width: 250px;
    //   p{
    //     width: 200px;
    //     line-height: 1.6;
    //   }
    //   &Title{
    //     font-size: 14px;
    //     color: rgba(255,255,255,.6);
    //     padding-bottom: 15px;
    //   }
    //   ul{
    //     li{
    //       margin-bottom: 10px;
    //     }
    //   }
    // }
  }
}

// h5
@media screen and (max-width: 580px) {
  .footer{
    .box{
      font-size: 12px;
      .right{
        padding: 10px;
        &City{
          display: none;
        }
        &City2{
          word-break: break-all;
          border: 1px solid #eee;
          line-height: 1.3;
          padding: 3px;
          border-radius: 3px;
          margin-right: 14px !important;
        }
        &Item{
          // word-break: break-all;
          padding-left: 8px;
          line-height: 1.3;
        }
      }
    }
  }
}
@media screen and (min-width: 581px) {
  .footer{
    .box{
      .right{
        &City2{
          display: none;
        }
      }
    }
  }
}