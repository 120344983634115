html,
body {
  padding: 0;
  margin: 0;
  font: 400 1em/1.4 Source Sans Pro, sans-serif;
  font-family: Source Sans Pro;
  overflow-x: hidden;
  /* font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */

  // antd全局样式修改
  .ant-btn-primary {
    background: #51b8b4;
    border: 1px solid transparent !important;
    &:hover,
    &:active,
    &:focus {
      background: rgba(28, 131, 117, 0.8) !important;
    }
  }
}

body * {
  /* font-family: Source Sans Pro; */
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

body a {
  color: #51b8b4;
  text-decoration: none;
}
body a:hover {
  color: #319793;
}

* {
  box-sizing: border-box;
}

img {
  /* max-width: 100%; */
  height: auto;
}

h1,
h2,
p,
ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  padding: 0.5rem 1rem;
  font-weight: bold;
}

.no-content {
  width: 100%;
  text-align: center;
}
